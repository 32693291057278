<template>
  <div class="container-fluid mb-0">
    <div class="row h-50" >
      <div class="col-2">
        <button  @click="this.$router.back()" class="back-button">
          <span><i class="fa fa-arrow-left fa-3x"></i></span>
        </button>
      </div>
      <div class="col d-flex justify-content-center align-items-end">
        <h5 class="text-center">{{ updatedTitle }}</h5>
      </div>
      <div class="column-tab col-3 align-content-end" v-if="!isxmlDifferenceView">
        <ul class="nav nav-tabs d-flex flex-row-reverse small">
          <li class="nav-item">
            <router-link
              class="nav-link py-1 px-2"
              :to="{name: 'XmlTransformView', params: routerParams}"
              @change="checkFiledatas()"
              >XML Transformer</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link py-1 px-2" :to="{name: 'FileDifferenceNew', params: routerParams}">Compare </router-link>
          </li>
        </ul>
      </div>
      <div class="col-2" v-else></div>
    </div>
  </div>
</template>
  
  <script>
// import Store from '../vuex/store'
import { getSessionItem, setSessionItem, removeSessionItem } from "../store/session.js";
import { useType } from "../composables/useType";
import { pageViews, type } from "@/utils/util";
import { getTitle } from "@/services/apiServices";
export default {
  name: "XmlandFileDifMenu",
  setup() {
    const { getType} = useType();
    return{
      getType,
      type
    }
  },
  computed: {
    convertedFileName: function () {
      // return Store.getters.getConvertedFileName;
      return getSessionItem("convertedFileName");
    },
    xslFileName: function() {
      return getSessionItem("xslFileName");
    },
    componentType: function() {
      return getSessionItem("componentType");
    },
    isxmlDifferenceView: function() {
      return (this.diffView === type.XML);
    },
    routerParams: function() {
      return this.$route.params;
    },
    updatedTitle: function() {
      return this.title;
    },
  },
  data() {
    return {
      responseBody: "",
      loading: false,
      title: "",
    };
  },
  methods: {
    display() {
      console.log("After store file Name:", this.convertedFileName);
    },
    isActiveTab(tabName) {
      return this.$route.name === tabName;
    },
    async updateTitle(xslId) {
      if (xslId) {
        try {
          const response = await getTitle(this.$acclConfigs, xslId, 'xsl');
          if (response.status == 200) {
            return this.title = response.data.title;
          }
          return;
        } catch (error) {
          console.error('Error making api call - getTitle()', error);
        }
      }
    },
  },
  watch: {
    '$route.params.xslParamId': {
      handler(xslId) {
        this.updateTitle(xslId); // Call updateTitle when params change
      },
      immediate: true,
    },
  },
  unmounted() {
    setSessionItem("type", type.XSL);
  },
  props: ["projectParamId", "xslParamId", "xmlParamId", "diffView"]
};
</script>

<style scoped>
.nav-link,
.nav-link:hover {
  color: black;
}

.nav-tabs {
  --bs-nav-tabs-border-width: none !important;
  border: 1px solid #1d2856;
  border-radius: 10px;
}

.nav-tabs .router-link-active {
  background-color: #da6a26 !important;
  color: white !important;
  font-size: 1.1rem !important;
  font-weight: bolder !important;
  border-radius: 10px;
}

button {
  border-radius: 50% !important;
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  border: none !important;
  -webkit-transition: background-color 1s, color 1s, -webkit-transform 0.5s;
  transition: background-color 1s, transform 0.5s;
  box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8);
}
.column-tab {
  width: fit-content;
}
button:hover {
  -webkit-transform: translateX(-5px);
}

.fa-3x {
  font-size: 1.5rem;
}

.back-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
    border: none;
    border-radius: 8px; /* Initial shape can be slightly rounded */
    box-shadow: 0 0 0 transparent; /* No visible shadow initially */
    -webkit-transition: background-color 1s, color 1s, transform 0.5s, box-shadow 0.5s, border-radius 0.5s;
    transition: background-color 1s ease, transform 0.5s ease, box-shadow 0.5s ease, border-radius 0.5s ease, text-shadow 0.1s ease;
}

.back-button:hover {
    box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8); /* Add the shadow */
    text-shadow: 1px 1px 1px #000, 0 0 1px #000, 0 0 1px #000; /* Add text shadow */
    border-radius: 50%; /* Change to a circle on hover */
}
</style>
