<template>
  <FileDifMenu :project-param-id="projectParamId" :xsl-param-id="xslParamId" :xml-param-id="xmlParamId" :diff-view="getType"/>
  <div class="file-upload-container mb-3" v-if="getType == type.XML">
    <input
      type="file"
      multiple
      @change="readFile()"
      ref="fileInput"
      class="file-input"
      style="display: none"
      accept=".xml"
    />

    <button
      @click="this.$refs.fileInput.click()"
      class="d-flex align-items-center"
    >
      Select File
    </button>
    <!-- <input type="file" ref="doc" @change="readFile($event)" /> -->
    <!-- <div>{{inputXML}}</div> -->
  </div>
  <div class="loader"></div>
  <div class="row">
    <!-- <div class="col-1">
      @onchange="setValuesToStore(responseBody.fileName)"
      <button class="btn" @click="this.$router.push('/')">
        <span><i class="fa fa-arrow-left fa-3x"></i></span>
      </button>
    </div> -->
    <!-- <div class="col" style="height: 40px">
      <h3 class="text-center">{{ responseBody.fileName }}</h3>
    </div> -->
    <!-- <span v-if="setValuesToStore(responseBody.fileName)"></span> -->
  </div>

  <div class="row">
    <div v-if="loading" class="process-loading">
      <div>
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
      <CodeDiff
      :old-string="leftFile"
      :new-string="rightFile"
      output-format="side-by-side"
      style="height: 75vh"
      language="XML"
      :filename="leftFileName"
      :newFilename="rightFileName"
    />

  </div>

</template>

<script>
import FileDifMenu from "./XmlandFileDifMenu.vue";
import axios from "axios";
// import Store from "../vuex/store.js";
// import { type } from '../views/util';
import { getCookie } from "../store/cookie.js";
import { getSessionItem, setSessionItem } from "../store/session.js";
import { type } from "@/utils/util";
import {makeOpearationRequest} from '@/services/apiServices'
// import func from 'vue-editor-bridge';
export default {
  name: "FileDifferenceNew",
  setup() {
    return {
      type,
      makeOpearationRequest
    };
  },
  data() {
    return {
      responseBody: {
        converted_xml: "Converted XML",
      },
      loading: true,
      inputXML: "File is not Uploaded ",

      leftFile: "",
      rightFile: "",
      leftFileName: "",
      rightFileName: "",
    };
  },

  components: {
    FileDifMenu,
    // CodeDiff,
  },
  mounted() {
    this.invokeShowDifference();
  },
  computed: {
    getType(){
      return (this.xslParamId && this.xmlParamId) ? type.XML : type.XSL;
    },
    userId() {
      //return Store.getters.getUserId;
      return getCookie("userId");
    },
    requestId() {
      //return Store.getters.getRequestId;
      return getSessionItem("requestId");
    },
    xmlRequestId() {
      return getSessionItem("xmlrequestId");
    },
  },
  methods: {
    invokeShowDifference() {
      this.showDifference().then((data) => {
        this.leftFile =
          this.getType == type.XML ? "Please upload a file." : data.inputXsl;
        this.leftFileName = this.getType == type.XML ? '' : 'Source File';
        this.rightFile =
          this.getType == type.XML ? data.transformedXml : (data.convertedXsl ? data.convertedXsl : "");
        
        let rightFileTitle = 'Converted File'
        if('tiboCount' in data && data.tiboCount == 0) {
          rightFileTitle = 'Converted File - No TIBCO functions in source file'
        }
        this.rightFileName = this.getType == type.XML ? data.fileName : rightFileTitle;
      });

      
    },

    readFile() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {

          var fileContent = e.target.result;
          this.leftFile = fileContent; 
          this.leftFileName = file.name;
        };
        reader.readAsText(file);
      }
    },
    async showDifference() {
      var requestId =  {
        requestId : this.getType === type.XML? this.xmlRequestId : this.requestId,
      }; 
      !requestId.requestId ? ((this.getType === type.XML && this.xmlParamId) ? requestId = {requestId : this.xmlParamId} : requestId = {requestId : this.xslParamId}) : '';
      var typeResult = this.getType == type.XML ? type.XML : type.XSL;
      var body = {
        userId: this.userId,
        type: typeResult,
        
      };
      body = {...body,...requestId}
      //const endPoint = this.getType == type.XSL ? "/compare" : "/compare_xml"
      const endPoint = "/compare"
      
      //return this.makeOpearationRequest(endPoint,body)

      try {
        const response = await this.makeOpearationRequest(this.$acclConfigs, endPoint,body)
        console.log("Response",response)
        if (response.status === 200) {
          return response.data;
        } else {
          console.log("Failure in File Comparision :", response.data.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
      finally{
        this.loading = false;
      }
    },

  },
  props: ["projectParamId", "xslParamId", "xmlParamId"]
};
</script>


<style scoped>
/* File Upload Start */
.file-upload-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.file-upload-container button {
  filter: drop-shadow(0 15px 25px rgba(222, 98, 7, 0.15));
  display: inline-block;
  border: none;
  background: #da6a26;
  color: white;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  justify-content: center;
}

/* File upload End */
@import "~@fortawesome/fontawesome-free/css/all.min.css";

/* loading spinner Start */
.process-loading {
  /* background-color: aliceblue; */
  height: 68vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 3;
}

.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* loading spinner End */

.fa-3x {
  font-size: 1em;
}

h1 {
  padding-right: 10%;
}

/* button {
  margin-left: 15%;
  border-radius: 50% !important;
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  border: none !important;
  
  -webkit-transition: background-color 1s, color 1s, -webkit-transform 0.5s;
  transition: background-color 1s, transform 0.5s;
  box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8);
}

button:hover {
  -webkit-transform: translateX(-5px);
} */
</style>
<style>
.file-info {
  font-weight: bold !important;
}
</style>