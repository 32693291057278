<template>
  <div>
    <ViewTemplate :table-props="mappingColumns" :project-param-id="projectParamId" :mapper-param-id="mapperParamId" :default-selected-columns="defaultSelectedColumns"/>
  </div>
</template>

<script>
import { pageViews, type } from "../utils/util";
import Store from "../vuex/store.js";
import TabContentView from "./TabContentView.vue";
import ViewTemplate from "@/components/ViewTemplate.vue";

export default {
  name: pageViews.USAGEVIEW,
  methods: {
    setValuesToStore(type) {
      Store.commit("setType", type);
    }
  },
  mounted() {
    this.setValuesToStore(type.MAPPER_USAGE);
  },
  components: {
    ViewTemplate,
    TabContentView,
  },
  data() {
    return {
      uploadConfigs: {
        action: "generator",
        actionLabel: "Generator",
        title: "Mapper Config Generator",
        type: type.MAPPER_USAGE,
        accept: ".zip",
      },
      mappingColumns: {
        type: type.MAPPER_USAGE,
        isMultiSelectionAllowed: true,
        defaultSortOrder: { name: "asc", label: "Ascending" },
        defaultSortColumn: { name: "Process", label: "Process" },
        configs: [
          { name: "processLocation", label: "Process Location", order: 1, sort: true, default: true },
          { name: "process", label: "Process", order: 2, sort: true, default: true },
          { name: "subProcessLocation", label: "Sub Process Location", order: 3, sort: true, default: true },
          { name: "subProcess", label: "Sub Process", order: 4, sort: true, default: true },
          { name: "isActive", label: "Is Active", order: 5, sort: true, default: true }
        ],
        actions: {
          viewPopup: { label: 'View Output' },
          download: { label: 'Download' },
          calculate: { label: 'Calculate Complexity' },
        },
      },
      defaultSelectedColumns: ["process", "subProcess", "isActive"],
    };
  },
  props: ["projectParamId", "mapperParamId"],
}
</script>