<template>

    <div class="nav" v-if="navVisible">
        <a href="#" :class="getActiveCSSClass(currentView,pageViews.USAGEVIEW)" @click.prevent="selectTab(pageViews.USAGEVIEW)">Usage</a>
        <a href="#" :class="getActiveCSSClass(currentView,pageViews.TRANSITIONVIEW)" @click.prevent="selectTab(pageViews.TRANSITIONVIEW)">Transition</a>
        <a href="#" :class="getActiveCSSClass(currentView,pageViews.ACTIVITYTYPESVIEW)" @click.prevent="selectTab(pageViews.ACTIVITYTYPESVIEW)">Activity Types</a>
        <a href="#" :class="getActiveCSSClass(currentView,pageViews.FIELDMAPPINGVIEW)" @click.prevent="selectTab(pageViews.FIELDMAPPINGVIEW)">Field Mapping</a>
        <a href="#" :class="getActiveCSSClass(currentView,pageViews.COMPONENTVIEW)" @click.prevent="selectTab(pageViews.COMPONENTVIEW)">Use Cases</a>
    </div>
</template>

<script>
import { pageViews } from "../utils/util";

export default {
    name: pageViews.TAB_CONTENT_VIEW,
    data() {
        return {
            currentView: pageViews.USAGEVIEW,
            pageViews: Object.freeze(pageViews),
            navVisible: true,
        };
    },
    methods: {
        selectTab(view) {
            this.currentView = view;
            this.$emit('tab-selected', view);
        },
        getActiveCSSClass(currentView, view) {
            return currentView === view ? 'link router-link-active' : 'link';
        },
        toggleNav() {
            this.navVisible = !this.navVisible;
        }
    }
};
</script>

<style scoped>
.nav {
    display: inline-flex;
    justify-content: flex-start;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    margin-top: 14px;
    width: 100%;
}

.link {
    padding: 0px 13px;
    margin-right: 5px;
    margin-left: 5px;
    text-decoration: none;
    color: #da6a26; /* Initial font color */
    font-weight: 500;
    border: 1px solid transparent; /* Transparent border */
    border-radius: 12px; /* Rounded borders */
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 14px;
}
 
.link:hover {
    background-color: #f09f6d; /* Hover background */
    color: #fff; /* Hover font color */
}
 
.link.router-link-active {
    background-color: #da6a26; /* Active background */
    color: #fff; /* Active font color */
    border-color: #da6a26; /* Active border color */
    border-radius: 12px; /* Maintain rounded borders */
    text-decoration: underline; /* Underline the font */
}


</style>