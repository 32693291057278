export const app_mode = window.acclConfigs.VUE_APP_MODE || 'bulk';
export const api_mode = window.acclConfigs.VUE_APP_API_INTEGRATION_MODE || "actual"

export const action = {
    DELETE: "Delete",
    REPLACE: "Replace",
    REMOVE_CALC_ROW:"removeCalcRow"

};

export const user = {
    id: "userId",
    name: "userName",
    source: "source",
    role: "role",
};

export const role = {
    user: "user",
    admin: "admin",
    superadmin: "superadmin"
};

export const type = {
    XSL: "xsl",
    XML: "xml",
    PROJECT: "project",
    SCHEMA: "schema",
    KAFKA: "jms",
    CONNECTOR: "connector",
    MAPPER: "mapper",
    USER_ROLES: "users",
    MAPPER_USAGE:"mapper.usage",
    MAPPER_TRANSITION:"mapper.transition",
    MAPPER_ACTIVITIES:"mapper.activities",
    MAPPER_MAPPING:"mapper.mapping",
    MAPPER_COMPLEXITY:"mapper.complexity",
    MAPPER_COMPONENT:"mapper.component",
};
export const pageViews = {
    TRASHVIEW : "TrashView",
    FILEDIFFERENCE : "FileDifferenceNew",
    XMLFILEDIFFERENCE : "XMLFileDifferenceNew",
    XMLTRANSFORM: "XmlTransformView",
    LOGINVIEW: "LoginView",
    CONVERTERVIEW: "ConverterView",
    HEADERVIEW: "HeaderView",
    CALLBACKVIEW : "CallBackView",
    CANVASVIEW:"CanvasView",
    INDEPENDENT_ACC_VIEW: "IndependentAcceleratorsView",
    ALLINONE_ACC_VIEW: "AllInOneAcceleratorsView",
    VIEW_404: "UnderConstructionView",
    SCHEMACONVERTERVIEW: "SchemaConverterView",
    KAFKACONFIGGENERATORVIEW: "KafkaConfigGeneratorView",
    CONNECTOR_CONFIG_GENERATOR_VIEW: "ConnectorConfigGeneratorView",
    MANAGE_USERS_VIEW: "ManageUsersView",
    ERROR_VIEW: "ErrorPageView",
    FLOATING_COMPONENT: "FloatingComponent",
    MAPPERVIEW: "MapperView",
    TAB_CONTENT_VIEW: "TabContentView",
    USAGEVIEW: "UsageView",
    TRANSITIONVIEW:"TransitionView",
    ACTIVITYTYPESVIEW:"ActivityTypesView",
    FIELDMAPPINGVIEW:"FieldMappingView",
    COMPONENTVIEW:"ComponentView",


    
}

export const elementsToRemoveFromArray = ["actions", "accuracy", "*"];

export const invalidBEColumns = {
    [type.XSL]: ["actions", "accuracy", "*"],
    [type.XML]: ["*"],
    [type.SCHEMA]: ["*"],
    [type.PROJECT]: ["accelatorStatus","*"],
    [type.KAFKA]: ["*"],
    [type.USER_ROLES]: ["*"],
    [type.CONNECTOR]: ["*"],
    [type.MAPPER]: ["*"],
}
/**
 * Hard code requestId, as it is required for the report action
 * Hard code convertedCount & tibcoCount, as it is required for the accuracy calculation
 */
export const supportingColumns = {
    [type.XSL]: [
        "projectId",
        "xslId",
        "convertedCount",
        "tibcoCount",
        "status"
    ],
    [type.KAFKA]: [
        "projectId",
        "jmsId"
    ],
    [type.XML]: [
        "xmlId"
    ],
    [type.SCHEMA]: [
        "projectId",
        "xsdId",
    ],
    [type.PROJECT]: [
        "projectId",
        "xslAccuracy",
        "jmsAccuracy",
        "schemaAccuracy",
        "connectorAccuracy",
        "mapperAccuracy",
        "mapperStatus",
        "connectorStatus",
        "jmsStatus",
        "schemaStatus",
        "xslStatus",
        "accelatorStatus",

    ],
     [type.CONNECTOR]: [
        "projectId",
        "cfgId",
    ],
    [type.MAPPER]: [
        "projectId",
        "mapperId",
    ],
    [type.USER_ROLES]: [

    ],
}

export const CONVERSION_STATUS = {

    OK : { value : 'ok' , name : "Success" },
    PROGRESS : { value : 'in-prog', name : "In Progress" },
    FAIL : { value : 'fail', name : "Failed" },
    NEW : { value : 'new', name : "New" },
    NA: { value: 'NA', name: "Not Applicable"},
    TRUE: {value: 'true', name: "In-Active"},
    FALSE: {value: 'false', name: "Active"},

    getByValue: function(value) {
        let valueInLower = value.toString();
        for (let key in this) {
            if (this[key].value && this[key].value === valueInLower) {
                return this[key];
            }
        }
        throw new Error(`No enum constant with value ${value}`);
    },
    getByName: function(name) {
        for (let key in this) {
            if (this[key].name && this[key].name === name) {
                return this[key];
            }
        }
        throw new Error(`No enum constant with value ${name}`);
    }

}

export const STATUS_COLUMNS = [
    'status',
    'xslStatus',
    'schemaStatus',
    'jmsStatus',
    'connectorStatus',
    'mapperStatus',
    'isDisabled',
    'acceleratorStatus',

    
]

export const ACCURACY_COLUMNS_STATUS_MAP = {
    status: 'accuracy',
    xslStatus: 'xslAccuracy', 
    schemaStatus: 'schemaAccuracy',
    jmsStatus: 'jmsAccuracy',
    connectorStatus: 'connectorAccuracy',
    mapperStatus: 'mapperAccuracy',
    acceleratorStatus: 'acceleratorAccuracy',
}

export const DATE_COLUMNS = [
    'requestDate',
    'lastModifiedDate',
    'lastLoginTime',
]

export const GRID_ACTION_DATA_ELEMENTS = {
    [type.PROJECT] : {
        'fileNameKey': "fileName",
        'requestIdKey': "projectId",
        'projectIdKey': "projectId",
    },
    [type.XSL] : {
        'fileNameKey': "xslFileName",
        'requestIdKey': "xslId",
        'projectIdKey': "projectId",
    },
    [type.SCHEMA] : {
        'fileNameKey': "xsdName",
        'requestIdKey': "xsdId",
        'projectIdKey': "projectId",
    },
    [type.KAFKA] : {
        'fileNameKey': "fileName",
        'requestIdKey': "jmsId",
        'projectIdKey': "projectId",
    },
    [type.XML] : {
        'fileNameKey': "xmlFileName",
        'requestIdKey': "xmlId",
        'projectIdKey': "projectId",
    },
    [type.USER_ROLES] : {
        'requestIdKey': "userId",
        'projectIdKey': "projectId",
    },
    [type.CONNECTOR] : {
        'fileNameKey': "fileName",
        'requestIdKey': "cfgId",
        'projectIdKey': "projectId",
    },
    [type.MAPPER] : {
        'fileNameKey': "mapperFileName",
        'requestIdKey': "projectId",
        'projectIdKey': "projectId",
    },

}

export const ROUTER_PARAMS_GRID_FILTER_MAP = {
    [type.PROJECT] : {
        'projectParamId' : "projectId",
    },
    [type.XSL] : {
        'projectParamId' : "projectId",
        'xslParamId' : "xslId"
    },
    [type.SCHEMA] : {
        'projectParamId' : "projectId",
        'xsdParamId' : "xsdId"
    },
    [type.KAFKA] : {
        'projectParamId' : "projectId",
        'jmsParamId' : "jmsId"
    },
    [type.XML] : {
        'xslParamId' : "xslId",
        'xmlParamId' : "xmlId"
    },
    [type.CONNECTOR] : {
        'projectParamId' : "projectId",
        'configParamId' : "cfgId"
    },
    [type.MAPPER] : {
        'projectParamId' : "projectId",
        'mapperParamId' : "mapperId"
   },
    
}

export const ROUTER_PARAMS_VIEW_MAP = {
    [type.PROJECT] : {
        'projectParamId' : "projectId",
    },
    [type.XSL] : {
        'projectParamId' : "projectId",
        'xslParamId' : "requestId"
    },
    [type.SCHEMA] : {
        'projectParamId' : "projectId",
        'xsdParamId' : "requestId"
    },
    [type.KAFKA] : {
        'projectParamId' : "projectId",
        'jmsParamId' : "requestId"
    },
    [type.XML] : {
        'projectParamId' : "projectId",
        'xslParamId' : "xslId",
        'xmlParamId' : "requestId"
    },
}

export const EDITABLE_GRID_CHANGES_COLUMNS = {
    [type.USER_ROLES]: {
        "userName": "User Name",
        "role": "Role",
        "isDisabled": "State"
    },
}

export const EDITABLE_GRID_ACTIONS = {
    [type.USER_ROLES]: {
        inquiryEndpoint: "/search",
        ingestionEndpoint: "/updateuser",
    }
}

export function changeFileExtension(fileName, newExtension) {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      return fileName.substring(0, lastDotIndex) + "." + newExtension;
    }
    return fileName + "." + newExtension;
  }