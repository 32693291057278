<template >
  <!-- <UploadTemplate :upload-props="uploadConfigs"/> -->
  <div v-if="status">
    <IndependentAcceleratorsView />
    <ReportTemplate :table-props="xslColumns" :project-param-id="projectParamId" :xsl-param-id="xslParamId" />
    <!-- <CanvasView :upload-configs="uploadConfigs" /> -->
  </div>
</template>


<script>
// import UploadTemplate from './UploadTemplate.vue'
import "vue3-toastify/dist/index.css";
import ReportTemplate from "../components/ReportTemplate.vue";
import CanvasView from "../components/CanvasView.vue";
import IndependentAcceleratorsView from "../views/IndependentAcceleratorsView.vue";
import Store from "../vuex/store.js";
import { type,pageViews, app_mode } from "../utils/util";
export default {
  name: pageViews.CONVERTERVIEW,
  methods: {
    setValuesToStore(type) {
      Store.commit("setType", type);
    },
    /*callback() {
      const params = new URLSearchParams(window.location.search);
      const status = params.get("status");
      console.log("Status ", status);
      if (status === "success") {
        this.status = true;
        // Handle successful login
        const userId = params.get("user_id");
        console.log("User Id ", userId);
        // Store userId or fetch additional data
      } else if (status === "fail") {
        this.status = false;
        // Handle error
        const message = params.get("message");
        this.$router.push({
          name: "LoginView",
        });
        alert( message);
      }
    },*/
  },
  components: {
    IndependentAcceleratorsView,
    ReportTemplate,
    CanvasView,
    // UploadTemplate
  },
  mounted() {
    //this.callback()
    this.setValuesToStore(type.XSL);
  },
  unmounted() {
    console.log("unmounted");
  },

  activated() {
    console.log("activated");
  },
  deactivated() {
    console.log("deactivated");
  },

  data() {
    return {
      status: true,
      uploadConfigs: {
        action: "convert",
        actionLabel: "Convert",
        title: "Convert",
        type: type.XSL,
        accept: ".xsl",
      },
      xslColumns: {
        type: type.XSL,
        isMultiSelectionAllowed: true,
        configs: [
          {
            name: "dependentFunctionList",
            label: "Dependent Functions",
            order: 17,
          },
          {
            name: "customFunctionList",
            label: "Custom Functions",
            order: 16,
          },
          {
            name: "unAvailableFunctionList",
            label: "Unavailable Functions",
            order: 15,
          },
          {
            name: "nonConvertedCount",
            label: "Non Converted Count",
            order: 14,
          },
          {
            name: "failedFunctionList",
            label: "Failed Function List",
            order: 13,
          },
          {
            name: "failCount",
            label: "Failed Count",
            order: 12,
          },
          {
            name: "remarks",
            label: "Remarks",
            order: 11,
            isSortable: false,
            textAlign: "left",
            textOverflowStyle: "ellipsis",
          },
          {
            name: "lastModifiedDate",
            label: "Last Modified Date",
            order: 10,
            type: "date",
          },
          {
            name: "accuracy",
            label: "Accuracy",
            order: 9,
            defaultSelected: true,
            formula: {
              expression: "convertedCount / tibcoCount * 100",
              roundTo: 2,
            },
            suffix: "%",
            search: false,
          },
          {
            name: "lineCount",
            label: "Line Count",
            order: 8,
            defaultSelected: true,
          },
          {
            name: "convertedCount",
            label: "Converted Count",
            order: 7,
          },
          {
            name: "tibcoCount",
            label: "Tibco Count",
            order: 6,
          },
          {
            name: "status",
            label: "Status",
            isSortable: false,
            order: 5,
            defaultSelected: true,
            search: false
          },
          {
            name: "xslFileName",
            label: "File Name",
            order: 4,
            defaultSelected: true,
            checked: true,
            textAlign: "left",
          },
          {
            name: "requestDate",
            label: "Request Date",
            order: 1,
            defaultSelected: true,
            type: "date",
          },
          {
            name: "xslId",
            label: "XSL ID",
            order: 3,
          },
          {
            name: "projectId",
            label: "Project ID",
            order: 2,
          },
        ],
        actions: {
          download: {
          label : 'Download'
        },
          diff: {
          label : 'Show Difference'
        },
          transform: {
          label : 'Transform'
        },
        },
      },
    };
  },
  props: ["projectParamId", "xslParamId"],
};
</script>
