<template>
  <div class="popup-overlay" @click="closePopup">
    <div class="popup-content" @click.stop>
        <div class="d-flex align-items-center justify-content-between">
        <strong class="m-0 mx-auto ">{{ popup.viewTitle }}</strong>
        <button @click="closePopup" class="close-button">&times;</button>
      </div>
      <div class="sticky-header">
        <div class="fileName">{{ displayName }}</div>
        <div class="tab-buttons">
          <button v-for="(data, index) in popup.viewContent" :key="index" @click="selectedTab = index" :class="{ active: selectedTab === index }"> {{ data.title }}</button>
        </div>
      </div> 
      <Loading v-if="loading"/>
      <div class="tab-content" v-else>
        <div v-for="(data, index) in popup.viewContent" :key="index">
          <div v-if="data.title==='Lineage'  && selectedTab ===0">
            <TreeItem :data="data.content" />
          </div>
          <div v-else>
            <pre class="nowrap" v-if="selectedTab === index"><code :class="`language-${data.format}`">{{ data.content }}</code></pre>
          </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Loading from './Loading.vue';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';
import { registerHljsLineNumbers, injectHljsLineNumbersCss } from '../utils/highlight-line-numbers-plugin.js';
import TreeItem from './TreeItem.vue';

export default {
  props: {
    setShowPop: {
      type: Function,
      required: true
    },
    popup: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    displayName() {
      return this.popup.projectName === this.popup.fileName
        ? this.popup.projectName
        : `${this.popup.projectName} - ${this.popup.fileName}`;
    },
  },
  components: {
    TreeItem,
    Loading
  },
  methods: {
    closePopup() {
      this.setShowPop(false);
    },
    highlightCode() {
      this.$nextTick(() => {
        hljs.highlightAll();
        hljs.initLineNumbersOnLoad();
      });
    }
  },
  data() {
    return {
      selectedTab: 0,
      format: "yaml",
      textWrapping: false,

    }
  },
  watch: {
    selectedTab() {
      this.$nextTick(() => {
        this.highlightCode();
      });
    }
  },
  mounted() {
    window.dispatchEvent(new Event('hide-tooltips'));
    registerHljsLineNumbers(hljs);
    injectHljsLineNumbersCss();
    this.highlightCode();
  },
};
</script>

<style scoped>
.strong {
  font-size: larger;
}

.nowrap {
  white-space: pre !important;
  overflow: unset !important;
}

.tab-buttons {
  display: flex;
  /* margin-bottom: 10px; */
}
.tab-content {
  overflow: auto !important;
  max-height: calc(79vh - 100px);
  height: 90%;
  background: #F3F3F3;
  text-align: left;
  margin-top: -20px; /* Move the table towards the top */
}

.tab-buttons button {
  padding: 1px 3px;
  cursor: pointer;
  border: none;
  background-color: #ddd;
  border-radius: 5px;
  font-size: smaller;
  font-weight: bold;
  margin-right: 3px;
}

.tab-buttons button.active {
  background-color: #ddd;
  border-bottom: 3px solid #da6a26;
  border-radius: 5px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(0,0,0,0.5);
  z-index: 100;
}

.popup-content {
  background: white;
  padding: 10px; /* Adjusted padding */
  padding-top: 5px; /* Adjusted padding */
  border-radius: 8px;
  width: 80%; 
  max-width: 50rem; 
  height: 80%; 
  max-height: 80vh;
  position: relative;
  text-align: left; 
  border-style: ridge;
}

pre {
  margin: 0;
  margin-right: 0.7rem;
  padding: 1rem;
  color: #333;
  background: #f5f5f5;
  font-family: monospace;
  white-space: pre-wrap; 
  text-align: left;
}

pre code.hljs {
  overflow-x: unset !important;
}

button.close-button {
  background: transparent;
  color: red;
  border: none;
  font-size: 1.3rem; 
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}

button.close-button:hover {
  color: black;
}

.sticky-header {
  position: -webkit-sticky; 
  position: sticky;
  top: 0;
  background-color: white; 
  padding: 4px; /* Decreased padding */
  z-index: 1000; 
  margin-top: 0px; /* Reduce gap */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -5px; /* Adjusted margin */
  margin-top: -7px;
}

.header-content span {
  flex: 1;
  text-align: left;
}

.header-content .project-name {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.fileName{
  /* padding-top: 0.1rem; */
  padding-bottom: 0.3rem;
  flex:1;
  font-weight: bold;
}

.header-content .title {
  flex: 1;
  text-align: right;
  margin-left: 25%;
}

.header-content .close-button {
  flex: 1;
  text-align: right;
}

/*Below code commented for Toggle Text Wrapping Switch to be added as new feature
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px; 
}

slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: # #2196F3; 
}

input:checked + .slider:before {
  transform: translateX(14px); 
}

.wrap-text {
  white-space: normal; 
}*/
</style>
<style>

/* .hljs-ln-line {
  padding: 0; 
  margin: 0;
} */
.hljs-ln-numbers {
  text-align: center;
  color: rgb(31 35 40 / 38%);
  border-right: 1px solid #ddd;
  padding-right: 10px !important;
  background: #f5f5f5;
  vertical-align: top; /* Align to top if necessary */
  white-space: nowrap; /* Prevent line wrapping */
}
 
/* for block of code */
.hljs-ln-code {
    line-height: 0%;
    position: relative;
    left: -55px;
 
}
.hljs-ln-n {
  /* Styling for the line numbers */
  display: block;
  line-height: 1.5; /* Adjust as needed */
  padding: 0; /* Remove extra padding */
}
.hljs-attr {
  /* Styling for attributes */
  display: inline; /* Ensure no extra space is added */
  padding: 0; /* Remove extra padding */
}


</style>
