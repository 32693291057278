<template>
  <div
    class="modal fade"
    id="confirmModalDialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            {{ popup.title }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div v-if="Array.isArray(popup.body)" class="modal-body"
          style="overflow: scroll ;  height: fit-content; max-height: 13rem;">
          <div v-for="(column, index) in popup.body" :key="index">
            <p>{{ index + 1 }} . {{ column }}</p>
          </div>
        </div>
        <div v-else class="modal-body">
          <p>{{ popup.body }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ popup.secondaryActionLabel }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            @click="executePrimaryAction(this.popup.data)"
            data-bs-dismiss="modal"
          >
            {{ popup.actionLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
// import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
export default {
  name: "ConfirmModal",
  props: {
    popup: {
      type: Object,
      required: true,
    },
  },

  mounted() {
  },

  methods: {
    executePrimaryAction(data) {
      this.$emit("executePrimaryAction", data);
    },
    openModal() {
      
      const modal = document.getElementById("confirmModalDialog");

      if (!document.body.contains(modal) || modal.parentElement.tagName !== 'BODY') {
        document.body.appendChild(modal);
      }


      // Initialize the Bootstrap modal
      const myModal = new Modal(modal, {
        keyboard: false,
      });

      // Show the modal
      myModal.show();
    }
  },
};
</script>
<style scoped></style>
