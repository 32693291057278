<template>
    <div v-if="status">
        <IndependentAcceleratorsView />
        <ReportTemplate :table-props="connectorColumns" :project-param-id="projectParamId" :config-param-id="configParamId" />
    </div>
</template>

<script>
import ReportTemplate from "@/components/ReportTemplate.vue";
import { pageViews, type } from "../utils/util";
import Store from "../vuex/store.js";
import IndependentAcceleratorsView from "./IndependentAcceleratorsView.vue";

export default {
    name: pageViews.CONNECTOR_CONFIG_GENERATOR_VIEW,
    methods: {
        setValuesToStore(type) {
            Store.commit("setType", type);
        },
    },
    mounted() {
        this.setValuesToStore(type.CONNECTOR);
    },
    components: {
      ReportTemplate,
      IndependentAcceleratorsView
    },
    data() {
        return {
        status: true,
        uploadConfigs: {
            action: "generator",
            actionLabel: "Generator",
            title: "Connector Config Generator",
            type: type.CONNECTOR,
            accept: ".zip",
        },
        connectorColumns: {
            type: type.CONNECTOR,
            isMultiSelectionAllowed: true,
            configs: [
            {
              name: "cfgId",
              label: "Config ID",
              order: 2,
            },
            {
                name: "requestDate",
                label: "Request Date",
                order: 1,
                type: "date",
                search: false,
                defaultSelected: true
            },
            {
                name: "fileName",
                label: "File Name",
                order: 4,
                checked: true,
                defaultSelected: true,
                textAlign: "left",
            },
            {
                name: "status",
                label: "Status",
                defaultSelected: true,
                search: false,
                isSortable: false,
                order: 5,
            },
            {
                name: "lastModifiedDate",
                label: "Last Modified Date",
                order: 6,
                type: "date",
                search: false
            },
            {
                name: "totalConnections",
                label: "Total Connections",
                order: 7,
                search: false,
                defaultSelected: true
            },
            {
                name: "duplicateConnections",
                label: "Duplicate Connections",
                order: 8,
                search: false,
                defaultSelected: true
            },
            {
                name: "failedConnections",
                label: "Failed Connections",
                order: 9,
                search: false,
            },
            {
                name: "processedConnections",
                label: "Processed Connections",
                order: 10,
                search: false,
            },
            {
                name: "missingConnections",
                label: "Missing Connections",
                order: 11,
                search: false,

            },
            {
                name: "missingFiles",
                label: "Missing Files",
                order: 12,
                search: false,

            },
            {
                name: "remarks",
                label: "Remarks",
                order: 13,
                search: false,
                textAlign: "left",
                textOverflowStyle: "ellipsis",
            }
            ],
            actions: {
            view: {
                label: 'View Output'
            },
            download: {
                label: 'Download'
            },
            }
        }  
        };
    },
    props: ["projectParamId", "configParamId"],
}

</script>
