<template>

  <div class="hoverableSidebarMenu">
    <div class="xsl-converter-sidebar-menu" :style="topMarginStle" data-bs-toggle="offcanvas" data-bs-target="#uploadCanvas"
      aria-controls="staticBackdrop">
      <span class="label me-3 ">{{ uploadConfigs.title }}</span>
      <div class="xsl-icon">
        <img v-if= "uploadConfigs.action=='transform'" class= " " src="../assets/icons/xml-icon.svg" alt= " " />
        <img v-else-if= "uploadConfigs.action=='convert'" class= " " src="../assets/icons/xsl-icon.svg" alt= " " />
        <img v-else-if= "uploadConfigs.action=='bulk_process'" class= " " src="../assets/icons/zip-icon.svg" alt= " " />
      </div>
    </div>
  </div>

  <!-- OffCanvas Start -->

  <div class="offcanvas offcanvas-end" data-bs-backdrop="true" tabindex="-1" id="uploadCanvas"
    aria-labelledby="staticBackdropLabel">
    <div class="offcanvas-header">
      <h3 class="offcanvas-title" id="staticBackdropLabel">{{ uploadConfigs.title }}</h3>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div v-if= "uploadConfigs.action=='bulk_process'" class="offcanvas-body">
      <!--<UploadTemplate :upload-configs="uploadConfigs"/>-->
      <SingleFileUploadTemplate :upload-configs="uploadConfigs"/>
    </div>
    <div v-else class="offcanvas-body">
      <UploadTemplate :upload-configs="uploadConfigs"/>
      <!-- <SingleFileUploadTemplate :upload-configs="uploadConfigs"/> -->
    </div>
  </div>
  <!-- OffCanvas End -->
</template>
<script>
import UploadTemplate from './UploadTemplate.vue'
import SingleFileUploadTemplate from './SingleFileUploadTemplate.vue'

import { pageViews,action } from '../utils/util'
export default {
  name: pageViews.CANVASVIEW,
  props: {
    uploadConfigs: {
      type: Object,
      required : true
    },
    

  },
  computed: {
    topMarginStle() {
      let topValue = '3.0rem';
      if(this.uploadConfigs.action != 'bulk_process'){
        topValue = '5.9rem';
      }
      return {top: topValue};
    }
  },
  components: {
    UploadTemplate,
    SingleFileUploadTemplate
    
  },
  data() {
    return {
     temp:'../assets/icons/xml-icon.svg'
    };
  },
  methods: {
    handlePrimaryAction(data) {
      console.log(data);
      if (data.action === action.REPLACE) {
        //this.deleteRecord(data.userId, data.type, data.requestId);
      }
    },
  },
}
</script>
<style scoped>

.xsl-converter-sidebar-menu {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
  z-index: 1;
  top: 7.9%;
  position: fixed;
  right: 0;
  width: 2vw;  /* Initial width of the sidebar */
  color: #f0ece5;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: width 0.5s ease, background-color 0.5s ease;  /* Transition on width and background color */
  border-radius: 10px;
}

.xsl-converter-sidebar-menu img {
  height: 3vh;
}

.xsl-converter-sidebar-menu .label {
  color: black;
  white-space: nowrap;
  opacity: 0;  /* Initially hidden */
  transition: opacity 0.2s ease;  /* Transition for opacity */
}

.xsl-converter-sidebar-menu:hover {
  background-color: #d5e98e;  /* Change background color on hover */
  width: 7.5vw;  /* Expand the sidebar on hover */
}

.xsl-converter-sidebar-menu:hover .label {
  opacity: 1;  /* Fade in the label as sidebar expands */
  right: 2%;
}

.xsl-converter-sidebar-menu:hover .xsl-icon {
  left: 1%;
  right: 10%;  /* Move the icon on hover */
}

.xsl-icon{
  background-color:#1D2856;
  position: relative;
  /* left: -1.8rem; */
  right:2.28rem;
  border-radius: 10px;
  padding: 0.4vw;
} 

@media (min-width: 1920px) and (min-height: 1080px) {
  .xsl-icon {
    right: 2rem;
  }
}
</style>
