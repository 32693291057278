// stores/sortStore.js
import { defineStore } from 'pinia';

export const useSortStore = defineStore('sortStore', {
  state: () => ({
    sortStates: {}, // Store sort states for each page
  }),
  actions: {
    saveSortState(pageName, sortOrder, sortColumn, sortIndex) {
      this.sortStates[pageName] = { sortOrder, sortColumn, sortIndex };
    },
    getSortState(pageName) {
      return this.sortStates[pageName] || null;
    },
    clearSortStates() {
      this.sortStates = {};
    },
  },
  persist: {
    enabled: true, // Ensure persistence (requires pinia-plugin-persistedstate)
  },
});

export const useTableColumnStore = defineStore('tableColumns', {
  state: () => ({
    selectedTableStates: {}, // Store sort states for each page
  }),
  actions: {
    saveTableState(pageName, selectedConfigs) {
      this.selectedTableStates[pageName] = selectedConfigs;
    },

    getTableState(pageName) {
      return this.selectedTableStates[pageName] || null;
    },
    clearTableStates() {
      this.selectedTableStates = {};
    },
  },
  persist: {
    enabled: true, // Ensure persistence (requires pinia-plugin-persistedstate)
  },
});