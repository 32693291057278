import { defineStore } from 'pinia';

export const usePaginationStore = defineStore('pagination', {
  state: () => ({
    paginationStates: {}, // Store sort states for each page
  }),
  actions: {
    savePaginationState(pageName,  pageSize, currentPage) {
      this.paginationStates[pageName] = { pageSize, currentPage };
    },
    getPaginationState(pageName) {
      return this.paginationStates[pageName] || null;
    },
    clearPaginationStates() {
      this.paginationStates = {};
    },
  },
  persist: {
    enabled: true, // Ensure persistence (requires pinia-plugin-persistedstate)
  },
});