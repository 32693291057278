<template>
  <div>
    <ViewTemplate :table-props="mappingColumns" :project-param-id="projectParamId" :mapper-param-id="mapperParamId" :default-selected-columns="defaultSelectedColumns"/>
  </div>
</template>

<script>
import { pageViews, type } from "../utils/util";
import Store from "../vuex/store.js";
import TabContentView from "./TabContentView.vue";
import ViewTemplate from "@/components/ViewTemplate.vue";

export default {
  name: pageViews.COMPONENTVIEW,
  methods: {
    setValuesToStore(type) {
      Store.commit("setType", type);
    }
  },
  mounted() {
    this.setValuesToStore(type.MAPPER_COMPONENT);
  },
  components: {
    ViewTemplate,
    TabContentView,
  },
  data() {
    return {
      uploadConfigs: {
        action: "generator",
        actionLabel: "Generator",
        title: "Mapper Config Generator",
        type: type.MAPPER_COMPONENT,
        accept: ".zip",
      },
      mappingColumns: {
        type: type.MAPPER_COMPONENT,
        isMultiSelectionAllowed: true,
        defaultSortOrder: { name: "asc", label: "Ascending" },
        defaultSortColumn: { name: "Process", label: "Process" },
        configs: [
          { name: "processLocation", label: "Process Location", order: 1, sort: true, default: true },
          { name: "process", label: "Process", order: 2, sort: true, default: true },          
          { name: "complexity", label: "Complexity", order: 3, sort: true, default: true },
          { name: "mappingCount", label: "Mapping Count", order: 4, sort: true, default: true },
          { name: "transitionCount", label: "Transition Count", order: 5, sort: true, default: true },
          { name: "EMS", label: "EMS", order: 6, sort: true, default: true },
          { name: "JMS", label: "JMS", order: 7, sort: true, default: true },
          { name: "RV", label: "RV", order: 8, sort: true, default: true },
          { name: "Kafka", label: "Kafka", order: 9, sort: true, default: true },
          { name: "AWS SQS", label: "AWS SQS", order: 10, sort: true, default: true },
          { name: "RookS3 Adapter", label: "RookS3 Adapter", order: 11, sort: true, default: true },
          { name: "File Adapter", label: "File Adapter", order: 12, sort: true, default: true },
          { name: "IPFeedEnterprise Adapter", label: "IPFeedEnterprise Adapter", order: 13, sort: true, default: true },
          { name: "GPOS DB Adapter", label: "GPOS DB Adapter", order: 14, sort: true, default: true },
          { name: "Central DB Adapter", label: "Central DB Adapter", order: 15, sort: true, default: true },
          { name: "AS400 Adapter", label: "AS400 Adapter", order: 16, sort: true, default: true },
          { name: "Oracle EBS", label: "Oracle EBS", order: 17, sort: true, default: true },
          { name: "Java", label: "Java", order: 18, sort: true, default: true },
          { name: "Mappers", label: "Mappers", order: 19, sort: true, default: true },
          { name: "XML", label: "XML", order: 20, sort: true, default: true },
          { name: "AS400 DB", label: "AS400 DB", order: 21, sort: true, default: true },
          { name: "JDBC", label: "JDBC", order: 22, sort: true, default: true },
          { name: "Mail", label: "Mail", order: 23, sort: true, default: true },
          { name: "S/FTP", label: "S/FTP", order: 24, sort: true, default: true },
          { name: "File", label: "File", order: 25, sort: true, default: true },
          { name: "JSON", label: "JSON", order: 26, sort: true, default: true },
          { name: "Timer", label: "Timer", order: 27, sort: true, default: true },
          { name: "HTTP", label: "HTTP", order: 28, sort: true, default: true },
          { name: "SOAP", label: "SOAP", order: 29, sort: true, default: true },
          { name: "REST", label: "REST", order: 30, sort: true, default: true },
          { name: "SSL Cert", label: "SSL Cert", order: 31, sort: true, default: true }
        ],
        actions: {
          viewPopup: { label: 'View Output' },
          download: { label: 'Download' },
          calculate: { label: 'Calculate Complexity' },
        },
      },
      defaultSelectedColumns: [
        "process", "complexity", "mappingCount", "transitionCount", 
        "EMS", "JMS", "RV", "Kafka", "AWS SQS", "RookS3 Adapter", "File Adapter", 
        "IPFeedEnterprise Adapter", "GPOS DB Adapter", "Central DB Adapter", 
        "AS400 Adapter", "Oracle EBS", "Java", "Mappers", "XML", "AS400 DB", 
        "JDBC", "Mail", "S/FTP", "File", "JSON", "Timer", "HTTP", "SOAP", 
        "REST", "SSL Cert"
      ]
    };
  },
  props: ["projectParamId", "mapperParamId"],
}
</script>